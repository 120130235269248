// Add polyfills
import "whatwg-fetch";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "url-search-params-polyfill";

import format from "common/modules/format";
import IAPISettings from "framework/models/IAPISettings";
import userStore from "framework/stores/userStore";

import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { LoadingAction } from "framework/actions/apiActions";
import { LocaleStore } from "framework/stores/localeStore";
import { stringify } from "qs";
import { HttpVerb } from "framework/modules/requestHelper";
import { cacheResponse } from "common/modules/cacheHelper";
import { history } from "framework/modules/historyHelper";

export type RequestBaseError = {
    response: Response;
    error: any;
};

export type CacheOptions = {
    /** The key on which to store the response */
    key: string;
    /** Use global cache between systems, otherwise cache reponse per system */
    globalCache?: boolean;
    /** Number of days the cached response is valid */
    daysToCache?: number;
    /** Which key to store version of cache on. This is the key that will be compared to the version of the same key on the server */
    versionKey?: string;
};

export interface IOptions {
    query?: {};
    params?: string[];
    data?: {};
    headers?: { [key: string]: string };
    skipAuth?: boolean;
    /**
     * Abort any ongoing request with the same key. Useful to avoid sending multiple requests when we are not interested in the former responses
     * If abortPayload is set, we will return that data in the callback.
     * @type {string}
     * @memberof IOptions
     */
    abortKey?: string;
    /** returns this object in the callback if the request is aborted */
    abortPayload?: any;
    uriEncode?: boolean;
    responseType?: "blob";
    files?: MultipartRequestFile[];
    /**
     * If true the request will not be started if an identical (same endpoint, verb and options) request already exists.
     * GETs and POSTs will skip identical requests by default even if allowOnlyOne is undefined.
     */
    allowOnlyOne?: boolean;
    /** If true MARC will include all keys in the request response, even if their values are null */
    responseNullValues?: boolean;
    /**
     * If true MARC will ignore all keys in the response with default values and null values.
     * Does not work in conjunction with responseNullValues, skipResponseDefaultValues has higher priority.
     */
    skipResponseDefaultValues?: boolean;
    asForm?: boolean;
    overrideHost?: string;
    /** Set cacheoptions to cache request response */
    cacheOptions?: CacheOptions;
}

export type MultipartRequestFile = {
    file: Blob;
    fileName: string;
    contentType: string;
};

export interface IAPIResult<T = any> {
    response: Response;
    data: T;
}

function withSlash(endpoint: string) {
    if (endpoint && endpoint.length > 0) {
        return endpoint[0] === "/" ? endpoint : "/" + endpoint;
    }

    return endpoint;
}

function stripTrailingSlash(str: string): string {
    if (str && str.length > 0) {
        const lastCharIndex = str.length - 1;
        return str[lastCharIndex] === "/" ? stripTrailingSlash(str.substr(0, lastCharIndex)) : str;
    }

    return str;
}

function endpointify(apiUrl: string, apiVersion: string, endpoint: string): string {
    return endpoint.match("http[s]*://") ? endpoint : stripTrailingSlash(apiUrl) + withSlash(apiVersion) + withSlash(endpoint);
}

export class RequestBase<T> {
    public options: IOptions;
    private _settings: IAPISettings;
    private abortController: AbortController;
    private requestHeaders?: Headers;
    constructor(public verb: HttpVerb, public endpoint: string, options: IOptions | undefined, settings: IAPISettings) {
        this.options = options || {};
        this._settings = settings;
        if (options?.overrideHost) {
            this._settings.apiUrl = options.overrideHost;
        }
        this.endpoint = endpoint;
        this.abortController = new AbortController();
    }

    public systemHeader() {
        return this.requestHeaders?.get("system");
    }

    private getAcceptHeader = (): string => {
        if (this.options.skipResponseDefaultValues) {
            return "application/json+optimized-skipDefault, application/json; optimized-skipDefault, application/json";
        } else if (this.options.responseNullValues) {
            return "application/json";
        }
        return "application/json+optimized, application/json; optimized, application/json";
    };

    private getDefaultHeaders(r: Headers) {
        const settings = this._settings;
        settings.defaultAccepts = this.getAcceptHeader();
        if (settings.authorizationToken && settings.authorizationToken.length > 0) {
            r.set("Authorization", "Bearer " + settings.authorizationToken);
        }

        const storageManager = StorageManager.GetInstance();

        const impersonation = storageManager.retrieve("staff.impersonation", EStorageType.SessionStorage, true, null);
        if (impersonation) {
            r.set("x-impersonate", impersonation);
        }
        r.set("App", settings.applicationName);
        if (!this.options.files) {
            r.set("Content-Type", settings.defaultContentType);
        }
        if (this.options.asForm) {
            r.set("Content-Type", "application/x-www-form-urlencoded");
        }
        r.set("Accept", settings.defaultAccepts);
        r.set("Accept-Language", LocaleStore.getLocaleString());

        if (settings.systemName?.length > 0) {
            r.set("System", settings.systemName);
        }
        if (storageManager.retrieve("staff.debug.miniprofiler", EStorageType.LocalStorage, true, "hide") === "show") {
            r.set("miniprofiler", "1");
        }

        // This is used as a session Id, mainly to avoid handling our own events.
        if (settings.connectionId) {
            r.set("Client-Id", settings.connectionId);
        }
        return r;
    }

    private getQuery(query: string | object | undefined) {
        if (query) {
            return stringify(query, { addQueryPrefix: true, indices: false });
        }
        return "";
    }

    private getData(data?: string | object) {
        if (this.options.asForm) {
            return new URLSearchParams(<string | Record<string, string>>data);
        }
        if (this.options.files) {
            const form = new FormData();
            if (data && typeof data === "object") {
                for (const kvp of Object.entries(data)) {
                    form.append(kvp[0], JSON.stringify(kvp[1]));
                }
            }
            if (data && typeof data === "string") {
                form.append("data", data);
            }
            this.options.files.forEach((file) => {
                form.append(file.fileName, file.file);
            });
            return form;
        }
        if (data && !this.options.files) {
            return JSON.stringify(data);
        }
        return undefined;
    }

    private getHeaders(headers: { [key: string]: string } | undefined) {
        const setHeaders = new Headers();
        this.getDefaultHeaders(setHeaders);
        if (headers) {
            for (const key in headers) {
                setHeaders.set(key, headers[key]);
            }
        }
        return setHeaders;
    }

    private insertParams(endpoint: string): string {
        // string replace with options.params;
        return format(endpoint, this.options.params);
    }

    async fire(onComplete: ((request: RequestBase<T>, response: Response | null) => void) | null, cacheOptions?: CacheOptions): Promise<IAPIResult<T>> {
        (<any>window).openRequestCount += 1;

        const endpointParts = this.insertParams(this.endpoint).toUpperCase().split("?");
        const path = endpointParts[0].split("/");
        const params = endpointParts[1] ? endpointParts[1] : undefined;
        const endpoint = path.filter((p) => !!p).join("_");
        this._settings.defaultAccepts = this.getAcceptHeader();
        new LoadingAction(this.verb, endpoint, "REQUEST", params);

        this.requestHeaders = this.getHeaders(this.options.headers);

        const finalEndpoint = endpointify(this._settings.apiUrl, this._settings.apiVersion, this.insertParams(this.endpoint));
        return fetch(finalEndpoint + this.getQuery(this.options.query), {
            method: HttpVerb[this.verb],
            headers: this.requestHeaders,
            body: this.getData(this.options.data),
            signal: this.abortController.signal,
            credentials: "same-origin",
        })
            .then(async (response) => {
                const factorAuth = response.headers.get("x-userlogintype")?.toLowerCase();
                if (factorAuth === "bankid") {
                    userStore.bankIdRedirect(factorAuth);
                } else if (factorAuth === "pending") {
                    history.push("/me/profile");
                }
                if (onComplete != null) {
                    onComplete(this, response);
                }
                (<any>window).openRequestCount -= 1;
                if (response.ok) {
                    if (cacheOptions?.key) {
                        await cacheResponse(response, cacheOptions, this._settings.systemName);
                    }
                    new LoadingAction(this.verb, endpoint, "SUCCESS", params);
                    if (response.status === 204) {
                        return { data: undefined as T, response };
                    }
                    const contentType = response.headers.get("Content-Type");
                    if (contentType && contentType.match(/application\/json/)) {
                        return { data: <T>await response.json(), response };
                    }
                    return { data: <T>(<unknown>await response.blob()), response };
                }
                new LoadingAction(this.verb, endpoint, "FAIL", params);
                let error;
                try {
                    const contentType = response?.headers?.get("Content-Type");
                    if (contentType && contentType.match(/application\/json/)) {
                        error = await response.json();
                    } else {
                        error = await response.text();
                    }
                } catch (e) {
                    error = "";
                }
                // eslint-disable-next-line no-throw-literal
                throw { error: error, response };
            })
            .catch((error: Response) => {
                if (onComplete != null) {
                    onComplete(this, null);
                }
                new LoadingAction(this.verb, endpoint, "FAIL", params);
                // eslint-disable-next-line no-throw-literal
                throw error;
            });
    }

    abort() {
        (<any>window).openRequestCount -= 1;
        this.abortController.abort();
    }
}

export class GetRequest<T> extends RequestBase<T> {
    constructor(endpoint: string, options: IOptions | undefined, settings: IAPISettings) {
        super(HttpVerb.get, endpoint, options, settings);
    }
}

export class PostRequest<T> extends RequestBase<T> {
    constructor(endpoint: string, options: IOptions | undefined, settings: IAPISettings) {
        super(HttpVerb.post, endpoint, options, settings);
    }
}
export class PutRequest<T> extends RequestBase<T> {
    constructor(endpoint: string, options: IOptions | undefined, settings: IAPISettings) {
        super(HttpVerb.put, endpoint, options, settings);
    }
}
export class DeleteRequest<T> extends RequestBase<T> {
    constructor(endpoint: string, options: IOptions | undefined, settings: IAPISettings) {
        super(HttpVerb.delete, endpoint, options, settings);
    }
}
export class PatchRequest<T> extends RequestBase<T> {
    constructor(endpoint: string, options: IOptions | undefined, settings: IAPISettings) {
        super(HttpVerb.patch, endpoint, options, settings);
    }
}
