import * as React from "react";

import "common/less/global.less";

import Insights from "framework/modules/insights";
import "framework/stores/applicationStore"; //Imported only to initialize the store's action handling.
import RaygunStore from "framework/stores/raygunStore";
import { QueryClient, QueryClientProvider } from "react-query";

import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { handleUrlParameters } from "framework/systemUtils";
import { Location } from "history";
import { isApp, sendAppRouteNavigation } from "framework/modules/browserCheckHelper";

import SmartBanner from "react-smartbanner";

const asynCompLoading = (
    <>
        <div className="fake-navigation-menu"></div>
        <div className="applicationSplash">
            <div id="splashloading"></div>
            <div id="spinnerLoading" className="spinner isLoading">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
            </div>
        </div>
    </>
);

import asyncComp from "framework/components/asyncComponent/asyncComp";
const AuthRoot = asyncComp(() => import(/* webpackChunkName: "auth" */ "framework/components/auth/authRoot"), asynCompLoading);
const CheckoutWrapper = asyncComp(() => import(/* webpackChunkName: "checkout" */ "application/checkoutWrapper"), asynCompLoading);
const PublicWrappedApplication = asyncComp(() => import(/* webpackChunkName: "public" */ "framework/components/publicWrappedApplication"), asynCompLoading);
const WrappedApplication = asyncComp(() => import(/* webpackChunkName: "main" */ "framework/components/wrappedApplication"), asynCompLoading);

// const loadFA = async () => {
//     const { library } = await import("@fortawesome/fontawesome-svg-core");
//     const { default: fas } = await import("framework/faSolidImports");
//     const { default: far } = await import("framework/faRegularImports");
//     const { default: fal } = await import("framework/faLightImports");
//     const { default: fab } = await import("framework/faBrandImports");
//     const { default: fad } = await import("framework/faDuotoneImports");

//     library.add(fas, far, fal, fab, fad);
// };
// loadFA();
import { library } from "@fortawesome/fontawesome-svg-core";
import fas from "framework/faSolidImports";
import far from "framework/faRegularImports";
import fal from "framework/faLightImports";
import fab from "framework/faBrandImports";
import fad from "framework/faDuotoneImports";

library.add(fas, far, fal, fab, fad);

const queryClient = new QueryClient();

export const basePath = "/cloud";

export default class App extends React.PureComponent<{}, {}> {
    private isApp: boolean = false;
    UNSAFE_componentWillMount() {
        RaygunStore.init(); // need to reference raygun store somehow to force it to be included
        if (!window.location.pathname || window.location.pathname === "/") {
            localStorage.setItem("staff.redirectedFromRoot", "1");
        }
        if (window.location.href.includes("isApp")) {
            localStorage.setItem("staff.isApp", "true");
            this.isApp = true;
        }
    }

    smartBanner = () => {
        const isApp = this.isApp || localStorage.getItem("staff.isApp") === "true";
        if (isApp) {
            return null;
        }
        return <SmartBanner title="Caspeco Cloud" author="Caspeco AB" daysReminder={25} />;
    };

    render() {
        return (
            <QueryClientProvider client={queryClient}>
                {this.smartBanner()}
                <BrowserRouter basename={basePath}>
                    <Routes />
                </BrowserRouter>
            </QueryClientProvider>
        );
    }
}

const Routes = () => {
    const location = useLocation();
    const [prevPath, setPrevPath] = React.useState(location.pathname);
    React.useEffect(() => {
        handleUrlParameters();
    }, []);
    React.useEffect(() => {
        Insights.trackPageView(location.pathname);

        if (isApp()) {
            sendAppRouteNavigation(location.pathname);
        }

        // SM-1817: Track navigation from schedule and dailyrecon
        if (prevPath === "/schedule/scheduleView") {
            Insights.trackPageView("fromSchedule", location.pathname);
        }
        if (prevPath.includes("/time/dailyReconciliation")) {
            Insights.trackPageView("fromDailyRecon", location.pathname);
        }
        setPrevPath(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    /** Memo on internal routes because otherwise whole app will rerender twice on
     * hooks changed. First render on 'useLocation' and second render on saving
     * the previous path. No need to rerender on second state change as the state for previous path
     * only affects insights and not rendering of app
     */
    return <RoutesInternal location={location} />;
};

const RoutesInternal = React.memo((props: { location: Location }) => {
    const defaultRoute = "/home";
    return (
        <Switch>
            <Route path="/public" component={PublicWrappedApplication} />
            <Route path="/checkout" component={CheckoutWrapper} />
            <Route path="/auth" component={AuthRoot} key="auth" />
            <Redirect exact from="/" to={{ ...props.location, pathname: defaultRoute }} />
            <Route path="/" component={WrappedApplication} />
        </Switch>
    );
});
