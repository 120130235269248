import { ILocale } from "application/models/management/translation";
import { LocaleIdString } from "application/models/management/translation";
import { changeLocale } from "framework/actions/localeActionCreators";
import { LocaleStore } from "framework/stores/localeStore";
import { List } from "immutable";

type Params = { [index: string]: string } & {
    lang?: string;
};

function getUrlParams(): Params {
    let match: RegExpExecArray | null;
    const pl = /\+/g; // Regex for replacing addition symbol with a space
    const search = /([^&=]+)=?([^&]*)/g;
    const decode = (s: string) => {
        return decodeURIComponent(s.replace(pl, " "));
    };
    const query = window.location.search.substring(1);

    const urlParams: Params = {};

    while ((match = search.exec(query))) {
        urlParams[decode(match[1])] = decode(match[2]);
    }

    return urlParams;
}

function formatTargetLanguage(targetLanguage: string): LocaleIdString {
    // Format the last two letters to uppercase to match LocaleIdString-format
    let formatted = <LocaleIdString>(targetLanguage.substring(0, 3) + targetLanguage.substring(3, 5).toUpperCase());
    return formatted;
}

export function setLanguage(part?: string, language?: string) {
    let browserLanguages: List<LocaleIdString>;

    if (navigator.languages === undefined || navigator.languages === null) {
        browserLanguages = List<LocaleIdString>();
    } else {
        browserLanguages = List<LocaleIdString>(navigator.languages.filter((l) => l.length === 5));
    }

    const browserLanguage: LocaleIdString = LocaleStore.getCountryFromLanguage(navigator.language);

    let targetLanguage: LocaleIdString | null = null; // "sv-SE";
    let allowLocalStorage: boolean;
    try {
        allowLocalStorage = !!localStorage;
    } catch {
        allowLocalStorage = false;
    }
    const params = getUrlParams();
    if (params.lang && params.lang.length === 5) {
        targetLanguage = formatTargetLanguage(params.lang);
    } else if (language && language.length === 5) {
        targetLanguage = formatTargetLanguage(language);
    } else if (allowLocalStorage && localStorage.getItem("staff.locale")) {
        targetLanguage = <LocaleIdString>localStorage.getItem("staff.locale");
    }

    if (targetLanguage === null) {
        if (LocaleStore.isLanguageSupported(browserLanguage)) targetLanguage = browserLanguage;
        if (targetLanguage === null)
            browserLanguages.forEach((language: LocaleIdString): boolean => {
                if (language.length === 5 && LocaleStore.isLanguageSupported(language)) {
                    targetLanguage = language;
                    return false;
                }
                return true;
            });

        targetLanguage = targetLanguage || "sv-SE";
    }

    // Extra caution to make sure the targetlang really is formatted correctly as LocaleIdString.
    targetLanguage = formatTargetLanguage(targetLanguage);

    const locale: ILocale = { id: targetLanguage, name: targetLanguage, sortOrder: 1 };
    changeLocale(locale, part);
}
