import * as UserActions from "framework/actions/userActions";
import * as Moment from "moment-timezone";

import JwtDecode from "jwt-decode";
import Insights from "framework/modules/insights";
import Store from "./store";

import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { cookie } from "browser-cookie-lite";
import { AccessToken } from "framework/models/accessToken";
import { type User, UserManager, type UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import { clearAllCaches, clearExpiredCachedResponses } from "common/modules/cacheHelper";
import { sendAppMessage } from "framework/modules/browserCheckHelper";
import { settings as userManagerSettings } from "../modules/oidcHelper";
import { dispatch } from "framework/actions/action";
import { setSystemSSO } from "application/actions/systemActions";

type TokenDto = { sub: string };

export interface IUserStore {
    isAuthenticated(): boolean;
    getAccessTokenString(): string | null;
    getUIDeployment(): string;
    getTokenValidUntil(): IMoment | null;
    getSSO(): string | undefined;
    getIsJestOrCheckout(): boolean;
}

class UserStore extends Store implements IUserStore {
    private readonly _storageManager: StorageManager = StorageManager.GetInstance();
    private _isAuthenticated: boolean = false;

    private isJestOrCheckout: boolean;

    private _accessToken: AccessToken | null;
    private _subject: string = "";
    private _userManagerSettings: UserManagerSettings;
    private _userManager: UserManager;
    private _user: User | null;
    private ssoSigningOut: boolean;
    private silentIntervalRef: NodeJS.Timeout;
    private retryWaitTime: number = 1000;

    constructor() {
        super();
        this.isJestOrCheckout = document.location.pathname.startsWith("/cloud/checkout") || Boolean(process.env.JEST_WORKER_ID) || document.location.pathname.startsWith("/cloud/public");

        // Clear expired cache responses 5% of times loading cloud
        if (Math.random() < 0.05) {
            clearExpiredCachedResponses();
        }
        this._storageManager = StorageManager.GetInstance();

        this._userManagerSettings = userManagerSettings;

        this.handle(UserActions.LoginSuccess.actionType, this.handleLoginSuccess);
        this.handle(UserActions.LoginError.actionType, this.handleLoginError);

        this.handle(UserActions.Logout.actionType, this.handleLogout);

        if (!this.isJestOrCheckout) {
            this._userManager = new UserManager(this._userManagerSettings);
            this.ssoSigningOut = false;

            setTimeout(() => {
                // we delay this action just because it's not really important to us and we'd rather use time to perform important init.
                // need to JS ignore because types are wrong
                this._userManager
                    // @ts-ignore
                    .clearStaleState(new WebStorageStateStore({ store: window.localStorage }))
                    .then(() => {})
                    .catch((e) => console.warn("Clear stale state error", e));
            }, 1000);

            this._userManager.events.addSilentRenewError((e) => {
                console.warn("silent renew error", e);
                console.warn("silent error message", e.message);
                // the message is "login_required" if the session has expired, ie 24h for caspeco.support/developer-users
                if (e && e.message === "login_required") {
                    console.log("Login required, redirecting...");
                    this.silentLogin();
                } else {
                    // Timed out, no internet / sleeping
                    clearInterval(this.silentIntervalRef);
                    console.log("Starting silentLogin interval");
                    // Retry silent login after 1 second, then increment the time between retries up to 120 seconds.
                    this.retryWaitTime = 1000;
                    const retryCallBack = () => {
                        // cleanup localstorage
                        Object.keys(localStorage).forEach((key) => {
                            if (key.startsWith("oidc.")) localStorage.removeItem(key);
                        });
                        console.log(`${this.retryWaitTime}ms passed, calling silentlogin without redirect`);
                        clearInterval(this.silentIntervalRef);
                        this.retryWaitTime = Math.min(Math.floor(1.4 * this.retryWaitTime), 120000);
                        this.silentIntervalRef = setInterval(retryCallBack, this.retryWaitTime);
                        this.silentLogin(true);
                    };
                    this.silentIntervalRef = setInterval(retryCallBack, this.retryWaitTime);
                    this.emitChange();
                }
            });
            //@ts-ignore
            this._userManager.events.addUserSignedOut((e) => {
                console.warn("user signed out", e);
                this.silentLogin();
            });
            this._userManager
                .getUser()
                .then((user) => {
                    if (user && !user.expired) {
                        // set tokens
                        this.setUser(user);
                        this.setSystemSSO();
                    } else {
                        this.silentLogin();
                    }
                })
                .catch((r) => {
                    console.warn("user rejected", r);
                });
            this._userManager.events.addUserLoaded((user) => {
                this.setUser(user);
                this.emitChange();
            });
        }
    }

    getIsJestOrCheckout(): boolean {
        return this.isJestOrCheckout;
    }

    public bankIdRedirect(type: string) {
        this._userManager.signoutRedirect({
            extraQueryParams: {
                method: type.toLowerCase(),
            },
        });
    }

    public silentLogin(noRedirect: boolean = false) {
        this._userManager
            .signinSilent()
            .then((u) => {
                console.log("silent succes", u);
                this.setUser(u);
                clearInterval(this.silentIntervalRef);
                this.setSystemSSO();
                this.emitChange();
            })
            .catch((r) => {
                // Do not redirect if time out error, try again instead
                if (!noRedirect) this._userManager.signinRedirect({ state: { url: document.location.pathname } });
                console.warn("silent failed", r);
            });
    }

    handleLogout = (_action?: UserActions.Logout) => {
        if (this.ssoSigningOut) return;
        clearAllCaches();
        const message = "logout";
        sendAppMessage(message);
        this._subject = "";
        Insights.setCustomAttribute("user.username", null);
        this._storageManager.remove("staff.user.username", EStorageType.LocalStorage, true);
        if (this.isJestOrCheckout) {
            this._isAuthenticated = false;
            this._accessToken = null;
        } else {
            this.ssoSigningOut = true;
            this._userManager.signoutRedirect();
        }
        // this.emitChange();
    };

    handleLoginSuccess = (action: UserActions.LoginSuccess) => {
        this._loginAndRefreshSuccessHelper(action);

        const subject = this.getUserId();
        if (subject) {
            Insights.setUser(subject);
        }
        Insights.trackEvent("login.success");
        this.emitChange();
    };

    handleLoginError = (_action: UserActions.LoginError) => {
        console.log("Handle error, action:", _action);
        if (this.isJestOrCheckout) this._isAuthenticated = false;

        Insights.trackEvent("login.error");

        this.emitChange();
    };

    private _loginAndRefreshSuccessHelper = (action: UserActions.LoginSuccess) => {
        if (this.isJestOrCheckout) {
            const expiresIn: number = action.response.expires_in;
            this._accessToken = new AccessToken(action.response.access_token, expiresIn);
            this._isAuthenticated = true;
        }
    };

    getUIDeployment() {
        return cookie("RP-Deployment");
    }

    private setSystemSSO() {
        if (this._user && !this._user.expired) {
            dispatch(setSystemSSO);
        }
    }

    private setUser(user: User | null) {
        this._user = user;
    }

    getOrigin = () => {
        return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;
    };

    isAuthenticated() {
        if (this.isJestOrCheckout) {
            return this._isAuthenticated;
        }
        return !!this._user && !this._user.expired;
    }

    getAccessTokenString(): string | null {
        if (!this.isAuthenticated()) {
            return null;
        }
        if (!this.isJestOrCheckout) return this._user?.access_token ?? null;

        const validUntil = this.getTokenValidUntil();
        if (validUntil && validUntil < Moment().add(5, "minutes")) {
            if (validUntil < Moment()) {
                //To old to use, stop all calls until refreshed
                return null;
            }
        }
        return this._accessToken?.getEncoded() ?? null;
    }

    getTokenValidUntil(): IMoment | null {
        if (!this.isJestOrCheckout) {
            if (this.isAuthenticated() && this._user?.expires_at) {
                return Moment.unix(this._user.expires_at);
            }
            return null;
        }
        return this._accessToken?.expiresAt?.getMoment() ?? null;
    }

    getUserId() {
        if (((!this.isJestOrCheckout && this.isAuthenticated()) || (this.isJestOrCheckout && this._accessToken)) && !this._subject) {
            const impersonation = this._storageManager.retrieve("staff.impersonation", EStorageType.SessionStorage);
            if (impersonation) {
                this._subject = impersonation;
            } else {
                if (this.isJestOrCheckout) {
                    const encoded = this._accessToken?.getEncoded();
                    if (encoded) {
                        const decodedToken = JwtDecode<TokenDto>(encoded);
                        this._subject = decodedToken.sub;
                    }
                } else {
                    this._subject = this._user?.profile.sub ?? "";
                }
            }
        } else if (!this._subject) return null;
        return this._subject;
    }

    getSSO(): string | undefined {
        return this._userManagerSettings.authority;
    }
}

const userStore = new UserStore();
export default userStore;
