import Trace from "framework/modules/trace";
import { List, Map } from "immutable";
import * as Moment from "moment-timezone";
export { EStorageType } from "common/models/storageType";
import { EStorageType } from "common/models/storageType";

type GUID = string;

export enum EStorageManagerActionType {
    Retrieve = 0,
    Store = 1,
    Remove = 2,
    Clear = 3,
    SetPrefix = 4,
}

function newGuid(): GUID {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0;
        // eslint-disable-next-line no-bitwise
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

interface IKeyMeta {
    key: string;
    global: boolean;
    prefix: string | null;
}

interface IHistoryEntry {
    storage: EStorageType | null;
    action: EStorageManagerActionType;
    keyInfo: IKeyMeta | null;
    value: string | null;
    timestamp: IMoment;
}

class HistoryLog {
    private maxSize: number;
    private history: List<IHistoryEntry>;

    constructor(size: number) {
        this.maxSize = size;
        this.history = List<IHistoryEntry>();
    }

    addEntry(storage: EStorageType | null, action: EStorageManagerActionType, keyInfo: IKeyMeta | null, value: string | null) {
        const entry: IHistoryEntry = {
            storage: storage,
            action: action,
            keyInfo: keyInfo,
            value: value,
            timestamp: Moment(),
        };
        this.history = this.history.push(entry);
    }

    readHistory(entryAmount: number) {
        const array = this.history.toArray();
        if (entryAmount === -1) entryAmount = array.length - 1;
        let element = entryAmount > array.length - 1 ? array.length - 1 : entryAmount;
        let entry = null;
        for (element; element > 0; element--) {
            entry = array[element];
            console.warn(`HistoryLog[${element}] = ${JSON.stringify(entry)}`);
        }
    }
}

/**
 * Used as in memory storage if no localStorage is defined
 */
class InternalStorage implements Storage {
    private _storage: Map<string, any> = Map<string, any>();
    public readonly length: number;

    setItem(key: string, value: string): void {
        this._storage = this._storage.set(key, value);
    }

    getItem(key: string): string | null {
        return this._storage.get(key) || null;
    }

    removeItem(key: string): void {
        this._storage = this._storage.remove(key);
    }

    key(index: number): string | null {
        return this._storage.toKeyedSeq().toArray()[index] || null;
    }

    clear(): void {
        this._storage = this._storage.clear();
    }
}

export class StorageManager {
    private static instance: StorageManager | null = null;
    private readonly _storage: InternalStorage;

    private readonly _hasLocalStorage: boolean = false;
    private readonly _hasSessionStorage: boolean = false;
    private readonly _guid: GUID;
    private readonly _debugMode: boolean = false;

    private _history: HistoryLog;
    private _prefix: string | null = null;

    /**
     * Gets the instance of the StoreManager or instantiates it.
     */
    public static GetInstance() {
        if (StorageManager.instance === null) StorageManager.instance = new StorageManager();
        if (StorageManager.instance._debugMode) Trace.info("Serving StorageManager with GUID: " + StorageManager.instance._guid);
        return StorageManager.instance;
    }

    /**
     * Constructs a StoreManager
     */
    private constructor() {
        this._storage = new InternalStorage();
        try {
            this._hasLocalStorage = !!localStorage;
        } catch {
            this._hasLocalStorage = false;
        }
        try {
            this._hasSessionStorage = !!sessionStorage;
        } catch {
            this._hasSessionStorage = false;
        }
        this._debugMode = this._hasLocalStorage && localStorage && localStorage.getItem("staff.debugMode") === "true" ? true : false;
        this._guid = newGuid();
        this._history = new HistoryLog(150);
    }

    /**
     * Store a value with a key in a chosen Storage
     * @param key key for storage lookup
     * @param value value to store at key
     * @param where which Storage to store the value in
     * @param global if true the prefix will not be prepended to key, else prepend. (does not apply to EStorageType.SessionStorage)
     */
    public store(key: string, value: string, where: EStorageType, global: boolean = false): void {
        const storage: Storage | null = this.getStorage(where);
        if (where === EStorageType.SessionStorage || where === EStorageType.StorageManagerMemory) global = true;
        if (storage === null) return;
        this.setItem(storage, key, value, global);
        this._history.addEntry(where, EStorageManagerActionType.Store, { key: key, global: global, prefix: this._prefix }, value);
    }

    /**
     * Retrieve a value through its key from a chosen Storage
     * @param key key for lookup
     * @param where which Storage to store the value in
     * @param global if true the prefix will not be prepended to key, else prepend. (does not apply to EStorageType.SessionStorage)
     * @param fallback optional fallback value if key does not exist in storage.
     */
    public retrieve(key: string, where: EStorageType, global: boolean = false, fallback: string | null = null): string | null {
        const storage: Storage | null = this.getStorage(where);
        if (where === EStorageType.SessionStorage || where === EStorageType.StorageManagerMemory) global = true;
        if (storage === null) return null;
        const result: string | null = this.getItem(storage, key, fallback, global);
        this._history.addEntry(where, EStorageManagerActionType.Retrieve, { key: key, global: global, prefix: this._prefix }, result);
        return result;
    }

    /**
     * Remove a value through its key from a chosen Storage
     * @param key key for lookup
     * @param where which Storage to store the value in
     * @param global if true the prefix will not be prepended to key, else prepend. (does not apply to EStorageType.SessionStorage)
     */
    public remove(key: string, where: EStorageType, global: boolean = false) {
        const storage: Storage | null = this.getStorage(where);
        if (where === EStorageType.SessionStorage || where === EStorageType.StorageManagerMemory) global = true;
        if (storage === null) return;
        const removedValue: string | null = this.getItem(storage, key, null, global);
        this.removeItem(storage, key, global);
        this._history.addEntry(where, EStorageManagerActionType.Remove, { key: key, global: global, prefix: this._prefix }, removedValue);
    }

    /**
     * Clear a storage
     * @param where which Storage to clear
     */
    public clear(where: EStorageType) {
        const storage: Storage | null = this.getStorage(where);
        if (storage === null) return;
        storage.clear();
        this._history.addEntry(where, EStorageManagerActionType.Clear, null, null);
    }

    /**
     * Sets the prefix to be used for storing localised values to the currently system-user combination.
     * @param systemName the system name to be used for the prefix (indented to be the currently selected one)
     * @param subjectId the subjectId of the currently logged in user
     */
    public setPrefix(systemName: string, subjectId: string) {
        if (this._debugMode) Trace.info(`Setting prefix with arguments:\nsystemName: ${systemName}\nsubjectId: ${subjectId}`);
        this._prefix = this.generatePrefix(systemName, subjectId);
        this._history.addEntry(null, EStorageManagerActionType.SetPrefix, null, this._prefix);
    }

    /**
     *
     * @param nrEntries the amount of entires to read (if nrEntries > historyLog.length, it will read the whole history.)
     */
    public readHistory(nrEntries: number) {
        this._history.readHistory(nrEntries);
    }

    /**
     * Private help functions
     */
    private generatePrefix(systemName: string, subjectId: string): string | null {
        if (systemName === null || subjectId === null) return null;
        return `${subjectId}.${systemName}:`;
    }

    private getStorage(where: EStorageType) {
        let storage: Storage | null = null;
        switch (where) {
            case EStorageType.LocalStorage:
                storage = this._hasLocalStorage ? localStorage : null;
                break;
            case EStorageType.SessionStorage:
                storage = this._hasSessionStorage ? sessionStorage : null;
                break;
            case EStorageType.StorageManagerMemory:
                storage = this._storage;
                break;
            default:
                const unhandled: never = where;
                console.warn("Unhandled StorageType: ", unhandled);
                break;
        }
        if (this._debugMode) Trace.info("Storage found:", storage);
        return storage;
    }

    private setItem(storage: Storage, key: string, value: string, global: boolean) {
        const finalKey = this._prefix && !global ? this._prefix + key : key;
        if (this._debugMode) Trace.info("Storing (" + finalKey + ", " + value + ")");
        storage.setItem(finalKey, value);
    }

    private getItem(storage: Storage, key: string, fallback: string | null, global: boolean): string | null {
        const finalKey = this._prefix && !global ? this._prefix + key : key;
        const result: string | null = storage.getItem(finalKey) || storage.getItem(key) || fallback;
        if (this._debugMode) Trace.info("Retrieving (" + finalKey + ", " + result + ")");
        return result;
    }

    private removeItem(storage: Storage, key: string, global: boolean) {
        if (this._prefix && !global) key = this._prefix + key;
        if (this._debugMode) Trace.info("Removing (" + key + ")");
        storage.removeItem(key);
    }
}
