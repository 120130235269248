import * as Moment from "moment-timezone";

import AppDispatcher from "framework/dispatcher/appDispatcher";
import ApiStore from "./apiStore";
import RenderModeStore from "./renderModeStore";
import Store from "./store";
import UserStore from "./userStore";

import { cookie } from "browser-cookie-lite";
import { LocaleStore } from "./localeStore";
import { isApp } from "framework/modules/browserCheckHelper";
import rg4js, { RaygunOptions, RaygunPayload } from "raygun4js";
import { ISystemUserStore } from "application/stores/systemUserStoreInterfaces";
import { getSelectedSystem } from "application/actionCreators/systemActionCreators";
import { SystemStore } from "application/stores/systemStore";

const defaultRaygunOptions: RaygunOptions = {
    allowInsecureSubmissions: true,
    ignoreAjaxAbort: true,
    ignoreAjaxError: false,
    ignore3rdPartyErrors: true,
    excludedHostnames: ["localhost"],
};

export const rGunTrackOptions: RaygunOptions = {
    ...defaultRaygunOptions,
    debugMode: true,
};

export const rGunLiveOptions: RaygunOptions = {
    ...defaultRaygunOptions,
    debugMode: false,
};

export const rGunTrackApiKey = "proguwVJSL2+gGPcyIYfHw==";

export const rGunLiveApiKey = "3+0pTe8PFu3d2Y/tx+IXjQ==";

interface IRaygunStore {
    init(): void;
}

function isTrackingdev() {
    const dep = getCurrentDeployment();
    return dep === "trackingdev" || dep === "trackingdev-as-ne";
}

function getCurrentDeployment() {
    let version = cookie("RP-Deployment");
    if (!version) {
        if (document.location.hostname.indexOf("localhost") > -1) {
            version = "localhost";
        } else if (document.location.hostname.indexOf("trackingdev") > -1) {
            version = "trackingdev";
        }
    }

    return version;
}

type Action = {
    actionType: string;
    source?: string;
    createdAt: string;
    payload?: { url?: string };
};

class RaygunStore extends Store implements IRaygunStore {
    actionHistory: Action[] = [];
    systemUserStore: ISystemUserStore;
    constructor() {
        super();
        const initSystemUserStore = async () => {
            this.systemUserStore = (await import("application/stores/systemUserStore")).default;
        };
        initSystemUserStore();
        RenderModeStore.onChange(this._handleChange);
        ApiStore.onChange(this._handleChange);
        UserStore.onChange(this._handleChange);
        SystemStore.onChange(this._handleChange);
        LocaleStore.onChange(this._handleChange);
    }

    private beforeSend = (payload: RaygunPayload) => {
        try {
            const url: string = payload?.Details?.Request?.Url;
            if (url) {
                const parts = url.split("/");
                if (parts[2]?.match(/\d+\.\d+\.\d+\.\d+/)) {
                    return false;
                }
                if (parts[3]) {
                    payload.Details.Tags = payload.Details.Tags.filter((t: string) => !t.startsWith("section:"));
                    const tag = `section:${parts[3]}`;
                    payload.Details.Tags.push(tag);
                }
            }
        } catch (error) {
            console.warn("Could not extract and set tag");
        }
        return payload;
    };

    init(): void {
        try {
            // Re-init Raygun when we know what the ApiKey should be
            rg4js("options", rGunLiveOptions);
            rg4js("apiKey", rGunLiveApiKey);
            rg4js("enableCrashReporting", true);
            rg4js("onBeforeSend", this.beforeSend);
        } catch (error) {
            console.log("Unable to initialize raygun!", error);
        }
    }

    foo = AppDispatcher.register((payload): void => {
        const actionConstructor = payload.action.constructor;
        const action: Action = {
            actionType: actionConstructor.actionType || payload.action.actionType,
            createdAt: Moment.utc().format(),
            //source: payload.source,
            //action: payload.action
        };
        //Add extra data to actions
        if (payload.action?.operation?.url) {
            action.payload = { url: payload.action.operation.url };
        }

        this.actionHistory.unshift(action);
        this.actionHistory = this.actionHistory.slice(0, 150);
        this._handleChange();
    });

    private _handleChange = () => {
        let _renderMode: string = "unknown";
        let _orientation: string = "unknown";

        let _apiDeployment: string = "unknown";
        let _uiDeployment: string = "unknown";

        let _userSubject: string = "unknown";
        let _userLogin: string = "unknown";
        let _userEmail: string = "unknown";
        let _userFirstName: string = "unknown";
        let _userDisplayName: string = "unknown";

        let _systemName: string = "unknown";
        let _isLive: string = "unknown";
        let _systemBusinessName: string = "unknown";

        let _locale: string = "unknown";

        let _isApp: string = "unknown";

        // start collecting data
        const system = getSelectedSystem();
        const user = this.systemUserStore?.getCurrentUser().value;

        _renderMode = RenderModeStore.getMode();
        _orientation = RenderModeStore.getOrientation();

        if (system) {
            _apiDeployment = system.deploymentMarc;
            _uiDeployment = getCurrentDeployment();
            _systemName = system.name;
            _isLive = system.isLive ? "yes" : "no";
            _systemBusinessName = system.commonName;
        }

        if (user) {
            _userSubject = ""; //user.subject;
            _userLogin = user.username ?? "unknown";
            _userEmail = user.email ?? "unknown";
            _userFirstName = user.firstname ?? "unknown";
            _userDisplayName = user.name ?? "unknown";
        }

        _isApp = isApp() ? "yes" : "no";

        _locale = LocaleStore.getLocaleString();

        const warnedTruncate: { [actionType: string]: boolean } = {};
        const truncatedActionHistory: Action[] = [];
        const actionTypesCount: { [actionType: string]: number } = {};
        const maxActions = 5;
        this.actionHistory.forEach((action) => {
            if (truncatedActionHistory.length < 20) {
                const actionCount = (actionTypesCount[action.actionType] || 0) + 1;
                if (actionCount < maxActions) {
                    truncatedActionHistory.push(action);
                    actionTypesCount[action.actionType] = actionCount;
                } else if (!warnedTruncate[action.actionType]) {
                    truncatedActionHistory.push({ actionType: `truncated ${action.actionType} after ${maxActions} occurrences`, createdAt: action.createdAt });
                    warnedTruncate[action.actionType] = true;
                }
            }
        });
        // attach this to raygun reports
        const raygunData = {
            renderMode: _renderMode,
            renderOrientation: _orientation,
            apiDeployment: _apiDeployment,
            uiDeployment: _uiDeployment,
            userLogin: _userLogin,
            userEmail: _userEmail,
            userDisplayName: _userDisplayName,
            // userPermissions: _userPermissions,
            systemName: _systemName,
            systemIsLive: _isLive,
            systemBusinessName: _systemBusinessName,
            locale: _locale,
            actionHistory: truncatedActionHistory,
        };
        const tags = [`system:${_systemName}`, `isLive:${_isLive}`, `ui:${_uiDeployment}`, `isApp:${_isApp}`];

        try {
            rg4js("withCustomData", raygunData);
            rg4js("setUser", {
                identifier: _userLogin,
                isAnonymous: false,
                email: _userEmail,
                firstName: _userFirstName,
                fullName: _userDisplayName,
                uuid: _userSubject,
            });
            rg4js("withTags", tags);

            let version = _uiDeployment;
            if (version) {
                version = version.replace("-", ".");
                rg4js("setVersion", version);
            } else {
                rg4js("setVersion", "0.0.0.0");
            }
        } catch (error) {
            console.log("Unable to initialize raygun!", error);
        }
    };
}

const raygunStore = new RaygunStore();
export default raygunStore;
