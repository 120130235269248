import * as LoadingActions from "application/actions/loadingActions";
import * as ToastActions from "framework/actions/toastActions";
import Insights from "framework/modules/insights";
import * as Moment from "moment-timezone";

import IAPISettings from "framework/models/IAPISettings";
import ToastType from "framework/models/toaster/toasterTypeEnum";
import APIStore from "framework/stores/apiStore";

import { cookie } from "browser-cookie-lite";
import { translate as t } from "common/mixins/localeHelper";
import { UpdateDetected } from "../actions/applicationActions";
import { EStorageType, StorageManager } from "application/storageManager/storageManager";

import { AddAPIProfilerIds } from "framework/actions/apiActions";
import { DeleteRequest, GetRequest, IOptions as ImportedIOptions, PatchRequest, PostRequest, PutRequest, RequestBase, IAPIResult, CacheOptions } from "./requestBase";
import { SystemStore } from "application/stores/systemStore";
import UserStore from "framework/stores/userStore";
import { cachedResponseToResult, getCachedResponse } from "common/modules/cacheHelper";

export * from "./requestBase";

export interface IOptions extends ImportedIOptions {}

type IDeleteOptions = Except<IOptions, "data">;
type IGetOptions = Except<IOptions, "data">;

// Used by auto tests
(<any>window).openRequestCount = 0;

const MARC_ERRORS = [400, 410, 599];

export enum HttpVerb {
    get,
    post,
    put,
    delete,
    patch,
}

interface IAPIErrorData {
    message: string;
    stackTrace: string;
    exceptionMessage: string;
    exceptionType: string;
    code?: string;
    messageKey?: string;
    errorCode?: string;
}

export interface IAPIError {
    response: Response | null;
    error: IAPIErrorData | null;
}

export interface IAPIOperation {
    method?: Function;
    payload?: any;
    fromEvent?: boolean;
    url?: string;
    [key: string]: any;
}

type RequestFactory<T> = (f: (response: IAPIResult<T>) => IAPIResult<T>, e: any) => () => Promise<IAPIResult<T>>;

function isApiError(errorObject: IAPIError | DOMException): errorObject is IAPIError {
    return !(<DOMException>errorObject).code;
}

export class APIBase {
    private _settings: IAPISettings;
    private _apiVersion: string;
    /** Ongoing requests with an abortKey. If a request with an abort key is started, all requests with the same abortKey are aborted. */
    private _abortableRequests: { [key: string]: RequestBase<any>[] | undefined } = {};
    /**
     * All ongoing get requests.
     * Used to keep track of if an exactly identical request is being started, then it skipped, if allowOnlyOne is set in request options.
     * This is default for GETs and POST even if allowOnlyOne is undefined
     */
    private _ongoingRequests: { [key: string]: Promise<any> | undefined } = {};

    public readonly _storageManager: StorageManager;

    constructor() {
        this._updateSettings();
        APIStore.onChange(() => this._onApiStoreUpdate());
        UserStore.onChange(() => this._onApiStoreUpdate());
        SystemStore.onChange(() => this._onApiStoreUpdate());
        this._storageManager = StorageManager.GetInstance();
    }

    private _updateSettings() {
        this._settings = APIStore.getSettings();
    }

    private _onApiStoreUpdate() {
        this._updateSettings();
        if (this._settings.authorizationToken) {
            this.fireQueued();
        }
    }

    private isTestEnv = (): boolean => Boolean(process?.env?.JEST_WORKER_ID);

    setApiVersion(version: string) {
        this._apiVersion = version;
    }
    setApiSettings(settings: IAPISettings) {
        this._settings = settings;
    }

    async loadFailed(operation: IAPIOperation, errorObject?: IAPIError | DOMException) {
        let serverErrorInfo = null;
        const accessDeniedFromEvent =
            operation &&
            operation.fromEvent &&
            errorObject &&
            isApiError(errorObject) &&
            errorObject.response &&
            (errorObject.response.status === 401 || errorObject.response.status === 403);
        if (errorObject && isApiError(errorObject) && errorObject.response && errorObject.response.text) {
            try {
                try {
                    serverErrorInfo = errorObject.error;
                } catch (error) {
                    //Don't care if error is JSON or not.
                }
                if (serverErrorInfo && serverErrorInfo.stackTrace) {
                    console.error("Exception received from server:", serverErrorInfo.exceptionType, serverErrorInfo.exceptionMessage);
                    console.error(serverErrorInfo.stackTrace);
                }
            } catch (exception) {
                if (accessDeniedFromEvent) {
                    console.log("Object did not load on event: got an access denied, but the error is ignored.");
                } else {
                    console.log(errorObject.response.text);
                    console.error(exception);
                }
            }
        } else if (errorObject && !isApiError(errorObject)) {
            if (errorObject.code !== DOMException.ABORT_ERR) {
                console.error("Unknown DOMException: ", errorObject);
            }
        } else if (errorObject) {
            console.error("Unkwnown error:", errorObject);
        }
        if (accessDeniedFromEvent) {
            // Access denied when loading entity from event (ignore the error).
            new LoadingActions.LoadSuccess(operation);
        } else {
            if (errorObject && isApiError(errorObject) && errorObject.response && MARC_ERRORS.includes(errorObject.response.status) && serverErrorInfo && serverErrorInfo.code) {
                const message = `application.errors.${serverErrorInfo.code}.title`;
                new ToastActions.AddNew({ heading: message, type: ToastType.Error, alwaysVisible: true, detail: errorObject, serverErrorInfo: serverErrorInfo });
            } else {
                if (!errorObject) {
                    errorObject = { error: null, response: null };
                }
                if (isApiError(errorObject) || errorObject.code !== DOMException.ABORT_ERR) {
                    const devMode = this._storageManager.retrieve("staff.app.settings.debugBar", EStorageType.LocalStorage, true) === "1";
                    new ToastActions.AddNew({
                        heading: t("application.errors.somethingWentWrong"),
                        type: ToastType.Error,
                        detail: errorObject,
                        serverErrorInfo: serverErrorInfo ?? undefined,
                        timeout: 10000,
                        historyOnly: !devMode,
                    });
                }
            }
            if (errorObject && isApiError(errorObject) && errorObject.response && errorObject.response.url) {
                operation.url = errorObject.response.url;
            }
            new LoadingActions.LoadFail(operation);
        }
    }

    loadSuccess(operation: IAPIOperation) {
        new LoadingActions.LoadSuccess(operation);

        // these are useful for testing the appearance of toasts
        // let toastAction1 = new ToastActions.AddNew({ heading: "Något gick fel!", type: ToastType.Error});
        // let toastAction2 = new ToastActions.AddNew({ heading: "Något kanske gick rätt eller fel!", type: ToastType.Information });
        // let toastAction3 = new ToastActions.AddNew({ heading: "Något gick rätt!", type: ToastType.Success });
        // let toastAction4 = new ToastActions.AddNew({ heading: "Något kan gå fel i framtiden!", type: ToastType.Warning });
    }

    loadPending(operation: IAPIOperation) {
        setTimeout(() => new LoadingActions.LoadPending(operation));
    }

    getSettings() {
        const th = Moment().add(5, "minute");
        if (!this._settings.tokenValidUntil || this._settings.tokenValidUntil < th || this._settings.systemName === "") {
            this._updateSettings();
        }
        // override default settings
        if (this._apiVersion !== null) {
            this._settings.apiVersion = this._apiVersion;
        }

        return this._settings;
    }

    requestToKey = (endpoint: string, options: IGetOptions | IOptions | undefined, method: HttpVerb): string => {
        if (!options) options = {}; // null options is set to empty object in finalizeRequest. Need to do same here
        return endpoint + JSON.stringify(options) + `method:${method}`;
    };

    async get<T = any>(endpoint: string, options?: IGetOptions, outerPromise: boolean = false) {
        if (options?.uriEncode) {
            endpoint = encodeURI(endpoint);
        }
        let key: string;
        const skipIdentical = !outerPromise && (!options || options.allowOnlyOne === undefined || options.allowOnlyOne === true);
        if (skipIdentical) {
            key = this.requestToKey(endpoint, options, HttpVerb.get);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "GET " + endpoint });
                return <Promise<IAPIResult<T>>>this._ongoingRequests[key];
            }
        }
        if (options?.abortKey) {
            this.abort(options.abortKey);
        }
        const cachedResponse = await getCachedResponse(this._settings.systemName, this._storageManager, options);
        if (cachedResponse) return await (<Promise<IAPIResult<T>>>cachedResponseToResult(cachedResponse));
        let promise;
        if ((options && options.skipAuth) || this.hasAccessToken() || this.isTestEnv()) {
            const req = new GetRequest<T>(endpoint, options, this.getSettings());
            promise = this.fire(req, options?.cacheOptions);
        } else {
            const factory: RequestFactory<T> = (f, e) => () => {
                const req = new GetRequest<T>(endpoint, options, this.getSettings());
                return this.fire(req, options?.cacheOptions).then(f, e);
            };

            promise = this._enqueueRequest(factory);
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (skipIdentical) this._ongoingRequests[key!] = promise;
        return promise;
    }

    getAsync<T, R = T>(endpoint: string, mapper: (data: R, response: Response) => T, options?: IGetOptions): Promise<T> {
        // getAsync() returns a different promise than get(), since getAsync includes the mapper function.
        // Because of this we handle the storing of the requests outside the get-function when calling getAsync().
        let key: string;
        const skipIdentical = !options || options.allowOnlyOne === undefined || options.allowOnlyOne === true;
        if (skipIdentical) {
            key = this.requestToKey(endpoint, options, HttpVerb.get);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "GET " + endpoint });
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this._ongoingRequests[key]!;
            }
        }
        const promise = new Promise((resolve: (value: T) => void, reject: (reason: any) => void) => {
            // outerPromise = true means we do not store the inner promise of get() in _ongoingRequests
            this.get<R>(endpoint, options, true)
                .then((result) => {
                    const data = result.data;
                    if (options && options.abortKey) this._abortableRequests[options.abortKey] = undefined;
                    resolve(mapper(data, result.response));
                })
                .catch((errorObj: any) => {
                    if (options && options.abortKey) this._abortableRequests[options.abortKey] = undefined;
                    reject(errorObj);
                });
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (skipIdentical) this._ongoingRequests[key!] = promise;
        return promise;
    }

    async post<T = any>(endpoint: string, options?: IOptions, outerPromise: boolean = false) {
        let key: string;
        const skipIdentical = !outerPromise && (!options || options.allowOnlyOne === undefined || options.allowOnlyOne === true);
        if (skipIdentical) {
            key = this.requestToKey(endpoint, options, HttpVerb.post);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "POST " + endpoint });
                return <Promise<IAPIResult<T>>>this._ongoingRequests[key];
            }
        }
        if (options?.abortKey) {
            this.abort(options.abortKey);
        }
        const cachedResponse = await getCachedResponse(this._settings.systemName, this._storageManager, options);
        if (cachedResponse) return await (<Promise<IAPIResult<T>>>cachedResponseToResult(cachedResponse));
        let promise;
        if ((options && options.skipAuth) || this.hasAccessToken() || this.isTestEnv()) {
            const req = new PostRequest<T>(endpoint, options, this.getSettings());
            promise = this.fire(req);
        } else {
            const factory: RequestFactory<T> = (f, e) => () => {
                const req = new PostRequest<T>(endpoint, options, this.getSettings());
                return this.fire(req).then(f, e);
            };
            promise = this._enqueueRequest(factory);
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (skipIdentical) this._ongoingRequests[key!] = promise;
        return promise;
    }

    postAsync<T, R>(endpoint: string, mapper: (data: R) => T, options?: IOptions): Promise<T> {
        // postAsync() returns a different promise than post(), since postAsync includes the mapper function.
        // Because of this we handle the storing of the requests outside the post-function when calling postAsync().
        let key: string;
        const skipIdentical = !options || options.allowOnlyOne === undefined || options.allowOnlyOne === true;
        if (skipIdentical) {
            key = this.requestToKey(endpoint, options, HttpVerb.post);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "POST " + endpoint });
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this._ongoingRequests[key]!;
            }
        }
        const promise = new Promise((resolve: (value: T) => void, reject: (reason: any) => void) => {
            this.post<R>(endpoint, options, true)
                .then((result) => {
                    const data = result.data;
                    resolve(mapper(data));
                })
                .catch((errorObj: any) => {
                    reject(errorObj);
                });
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (skipIdentical) this._ongoingRequests[key!] = promise;
        return promise;
    }

    put<T = any>(endpoint: string, options?: IOptions, outerPromise: boolean = false) {
        let key: string;
        if (!outerPromise && options?.allowOnlyOne) {
            key = this.requestToKey(endpoint, options, HttpVerb.put);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "PUT " + endpoint });
                return <Promise<IAPIResult<T>>>this._ongoingRequests[key];
            }
        }
        if (options?.abortKey) {
            this.abort(options.abortKey);
        }
        let promise;
        if ((options && options.skipAuth) || this.hasAccessToken() || this.isTestEnv()) {
            const req = new PutRequest<T>(endpoint, options, this.getSettings());
            promise = this.fire(req);
        } else {
            const factory: RequestFactory<T> = (f, e) => () => {
                const req = new PutRequest<T>(endpoint, options, this.getSettings());
                return this.fire(req).then(f, e);
            };
            promise = this._enqueueRequest(factory);
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (!outerPromise && options?.allowOnlyOne) this._ongoingRequests[key!] = promise;
        return promise;
    }

    patch<T = any>(endpoint: string, options?: IOptions, outerPromise: boolean = false) {
        let key: string;
        if (!outerPromise && options?.allowOnlyOne) {
            key = this.requestToKey(endpoint, options, HttpVerb.patch);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "PATCH " + endpoint });
                return <Promise<IAPIResult<T>>>this._ongoingRequests[key];
            }
        }
        if (options?.abortKey) {
            this.abort(options.abortKey);
        }
        let promise;
        if ((options && options.skipAuth) || this.hasAccessToken() || this.isTestEnv()) {
            const req = new PatchRequest<T>(endpoint, options, this.getSettings());
            promise = this.fire(req);
        } else {
            const factory: RequestFactory<T> = (f, e) => () => {
                const req = new PatchRequest<T>(endpoint, options, this.getSettings());
                return this.fire(req).then(f, e);
            };
            promise = this._enqueueRequest(factory);
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (!outerPromise && options?.allowOnlyOne) this._ongoingRequests[key!] = promise;
        return promise;
    }

    putAsync<T, R>(endpoint: string, mapper: (data: R) => T, options?: IOptions): Promise<T> {
        let key: string;
        if (options?.allowOnlyOne) {
            key = this.requestToKey(endpoint, options, HttpVerb.put);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "PUT " + endpoint });
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this._ongoingRequests[key]!;
            }
        }
        const promise = new Promise((resolve: (value: T) => void, reject: (reason: any) => void) => {
            this.put<R>(endpoint, options, true)
                .then((result) => {
                    const data = result.data;
                    resolve(mapper(data));
                })
                .catch((errorObj: any) => {
                    reject(errorObj);
                });
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (options?.allowOnlyOne) this._ongoingRequests[key!] = promise;
        return promise;
    }

    patchAsync<T, R>(endpoint: string, mapper: (data: R) => T, options?: IOptions): Promise<T> {
        let key: string;
        if (options?.allowOnlyOne) {
            key = this.requestToKey(endpoint, options, HttpVerb.patch);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "PATCH " + endpoint });
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this._ongoingRequests[key]!;
            }
        }
        const promise = new Promise((resolve: (value: T) => void, reject: (reason: any) => void) => {
            this.patch<R>(endpoint, options, true)
                .then((result) => {
                    const data = result.data;
                    resolve(mapper(data));
                })
                .catch((errorObj: any) => {
                    reject(errorObj);
                });
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (options?.allowOnlyOne) this._ongoingRequests[key!] = promise;
        return promise;
    }

    delete<T = any>(endpoint: string, options?: IDeleteOptions, outerPromise: boolean = false) {
        let key: string;
        if (!outerPromise && options?.allowOnlyOne) {
            key = this.requestToKey(endpoint, options, HttpVerb.delete);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "DELETE " + endpoint });
                return <Promise<IAPIResult<T>>>this._ongoingRequests[key];
            }
        }
        if (options?.abortKey) {
            this.abort(options.abortKey);
        }
        let promise;
        if ((options && options.skipAuth) || this.hasAccessToken() || this.isTestEnv()) {
            const req = new DeleteRequest<T>(endpoint, options, this.getSettings());
            promise = this.fire(req);
        } else {
            const factory: RequestFactory<T> = (f, e) => () => {
                const req = new DeleteRequest<T>(endpoint, options, this.getSettings());
                return this.fire(req).then(f, e);
            };
            promise = this._enqueueRequest(factory);
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (!outerPromise && options?.allowOnlyOne) this._ongoingRequests[key!] = promise;
        return promise;
    }

    /**
     * ReverseProxy does not allow DELETE to have an request body, send data as query params
     */
    deleteAsync<T = any, R = any>(endpoint: string, mapper: (data: R) => T, options?: IOptions): Promise<T> {
        let key: string;
        if (options?.allowOnlyOne) {
            key = this.requestToKey(endpoint, options, HttpVerb.delete);
            if (this._ongoingRequests[key]) {
                Insights.trackEvent("api.skipped_duplicate_request", { url: "DELETE " + endpoint });
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this._ongoingRequests[key]!;
            }
        }
        const promise = new Promise((resolve: (value: T) => void, reject: (reason: any) => void) => {
            this.delete<R>(endpoint, options, true)
                .then((result) => {
                    const data = result.data;
                    resolve(mapper(data));
                })
                .catch((errorObj: any) => {
                    reject(errorObj);
                });
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (options?.allowOnlyOne) this._ongoingRequests[key!] = promise;
        return promise;
    }

    private _reqQueu: (() => Promise<IAPIResult<any>>)[] = [];

    _enqueueRequest<T>(requestFactory: RequestFactory<T>) {
        let handler: () => Promise<IAPIResult<T>>;

        const cb = (fulfill: (value: IAPIResult<T>) => any, reject: (reason: any) => void) => {
            handler = requestFactory(fulfill, reject);
        };

        const q1 = new Promise<IAPIResult<T>>(cb);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._reqQueu.push(handler!);
        return q1;
    }

    fire<T>(req: RequestBase<T>, cacheOptions?: CacheOptions) {
        this.beginRequest(req);
        return req.fire(this.finalizeRequest, cacheOptions);
    }

    hasAccessToken() {
        const settings = this.getSettings();
        return !!settings.authorizationToken;
    }

    fireQueued() {
        if (this.hasAccessToken()) {
            const queue = this._reqQueu;
            this._reqQueu = [];
            queue.forEach((trigger) => {
                console.log("Processing request to in queue", trigger);
                trigger();
            });
        }
    }

    beginRequest = <T>(request: RequestBase<T>) => {
        const key = request.options.abortKey;
        if (key) {
            const ongoing = this._abortableRequests[key];
            if (ongoing) {
                ongoing.push(request);
            } else {
                this._abortableRequests[key] = [request];
            }
        }
    };

    finalizeRequest = <T>(request: RequestBase<T>, response: Response | null) => {
        const uiDeployment = response && response.headers.get("ui-deployment");
        if (request.systemHeader() && uiDeployment) {
            const deploymentCookie = cookie("RP-Deployment");
            if (deploymentCookie && deploymentCookie !== uiDeployment) {
                //Make sure we don't dispatch in a dispatch
                setTimeout(() => new UpdateDetected(uiDeployment), 0);
            }
        }
        this._ongoingRequests[this.requestToKey(request.endpoint, request.options, request.verb)] = undefined;
        const abortKey = request.options.abortKey;
        if (abortKey) {
            const ongoing = this._abortableRequests[abortKey];
            if (ongoing) {
                const requestIndex = ongoing.indexOf(request);
                if (requestIndex > -1) {
                    ongoing.splice(requestIndex);
                }
            }
        }

        // handle miniprofiler id's
        const mpIds = response && response.headers.get("x-miniprofiler-ids");
        const marcDeployment = response && response.headers.get("marc-deployment");
        if (mpIds) {
            const ids: string[] = JSON.parse(mpIds);
            new AddAPIProfilerIds(ids, marcDeployment ?? undefined);
        }
    };

    abort = (key: string) => {
        const ongoing = this._abortableRequests[key];
        let payloads: any[] = [];
        if (ongoing) {
            payloads = ongoing.map((request) => {
                request.abort();
                // Remove all aborted requests from ongoingRequests
                this._ongoingRequests[this.requestToKey(request.endpoint, request.options, request.verb)] = undefined;
                return request.options.abortPayload;
            });
        }
        this._abortableRequests[key] = [];
        return payloads;
    };
}
