/* eslint-disable no-restricted-imports */
import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { CacheOptions, IAPIResult, IOptions } from "framework/modules/requestBase";

const oneDayInMs: number = 86400000; // 1000 * 60 * 60 * 24
const globalCacheKeys = "staff.globalCacheKeys";

export const cacheResponse = async (response: Response, cacheOptions: CacheOptions, systemName: string) => {
    if (!caches) return;
    const storageManager = StorageManager.GetInstance();
    const subjectId = storageManager.retrieve("staff.currentUserId", EStorageType.LocalStorage, true);
    const cache = await (cacheOptions.globalCache ? caches.open(`staff.global:${subjectId}`) : caches.open(`staff.${systemName}:${subjectId}`));
    // Clone response to read data stream multiple times
    const responseToCache = response.clone();
    // Add own header with cachedate
    const headers = new Headers(responseToCache.headers);

    // store cache version
    if (cacheOptions.versionKey) {
        const serverKeysJson = storageManager.retrieve(cacheOptions.globalCache ? globalCacheKeys : `staff.${systemName}:cacheKeys`, EStorageType.LocalStorage);
        const serverKeysObj = serverKeysJson ? JSON.parse(serverKeysJson) : {};
        const version = serverKeysObj[cacheOptions.versionKey];
        headers.append("staff.cache-version-key", cacheOptions.versionKey);
        headers.append("staff.cache-version", version);
    }

    headers.append("staff.cache-created-on", new Date().toISOString().slice(0, 10));
    if (cacheOptions.daysToCache) headers.append("staff.days-to-cache", cacheOptions.daysToCache.toString());
    responseToCache.blob().then(async function (body) {
        // we add '/' to store the key as an url to avoid weird autoprefixing
        await cache.put(
            `/${cacheOptions.key}`,
            new Response(body, {
                status: responseToCache.status,
                statusText: responseToCache.statusText,
                headers: headers,
            })
        );
    });
};

export const getCachedResponse = async (systemName: string, storageManager: StorageManager, options?: IOptions): Promise<Response | null> => {
    if (!options?.cacheOptions?.key || !caches) return null;
    const subjectId = storageManager.retrieve("staff.currentUserId", EStorageType.LocalStorage, true);
    const cache = await (options.cacheOptions.globalCache ? caches.open(`staff.global:${subjectId}`) : caches.open(`${systemName}:${subjectId}`));
    const response = await cache.match(`/${options.cacheOptions.key}`);
    if (!response) return null;

    // check cache vesrsion
    if (options.cacheOptions.versionKey) {
        const cachedVersionKey = response.headers.get("staff.cache-version-key");
        const cachedVersionNumber = response.headers.get("staff.cache-version");
        if (!cachedVersionKey || cachedVersionNumber == null || options.cacheOptions.versionKey !== cachedVersionKey) return null;
        const serverKeysJson = storageManager.retrieve(options.cacheOptions.globalCache ? globalCacheKeys : `staff.${systemName}:cacheKeys`, EStorageType.LocalStorage);
        const serverKeysObj = serverKeysJson ? JSON.parse(serverKeysJson) : {};
        if (
            // The stored version number and the server's version number do not match -> wrong version, do not return cached response
            (Object.keys(serverKeysObj).includes(cachedVersionKey) && serverKeysObj[options.cacheOptions.versionKey] !== +cachedVersionNumber) ||
            // handles initial state when no cache key on server,
            // but the request has been cached on the client with the versionNumber stored as "undefined"
            // we treat this case as a valid version -> do not return null here, use the cached response
            (!Object.keys(serverKeysObj).includes(cachedVersionKey) && cachedVersionNumber !== "undefined") ||
            // the version for this cache has been invalidated in the client if it is -1 -> wrong version, do not return cached response
            serverKeysObj[options.cacheOptions.versionKey] === -1
        ) {
            console.log("wrong version for: ", options.cacheOptions.key);
            return null;
        }
    }

    // check cache deployment
    if (
        response.headers.get("ui-deployment") !== storageManager.retrieve("staff.currentUiDeployment", EStorageType.LocalStorage, true) ||
        response.headers.get("marc-deployment") !== storageManager.retrieve("staff.currentMarcDeployment", EStorageType.LocalStorage, true)
    ) {
        console.log(options.cacheOptions.key, " cached on different deployment, deleting old cache");
        // Response has been cached on different deployment
        // The new response will overwrite the cached response
        return null;
    }

    // check cache days valid
    if (options.cacheOptions.daysToCache) {
        const cacheDate = Date.parse(response.headers.get("staff.cache-created-on") as string);
        const now = new Date().getTime();
        const daysBetweenDates = Math.round((now - cacheDate) / oneDayInMs);
        if (daysBetweenDates > options.cacheOptions.daysToCache) {
            // Cached response no longer valid
            cache.delete(`/${options.cacheOptions.key}`);
            return null;
        }
    }

    return response;
};

export const cachedResponseToResult = async <T = any>(response: Response): Promise<IAPIResult<T>> => {
    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.match(/application\/json/)) {
        return { data: <T>await response.json(), response };
    }
    return { data: <T>(<unknown>await response.blob()), response };
};

export const clearAllCaches = () => caches?.keys().then((keys) => keys.forEach(async (key) => await caches.delete(key)));

export const clearGlobalCache = async () => {
    if (!caches) return false;
    const storageManager = StorageManager.GetInstance();
    const subjectId = storageManager.retrieve("staff.currentUserId", EStorageType.LocalStorage, true);
    return await caches.delete(`staff.global:${subjectId}`);
};

export const clearSystemCaches = () => {
    caches?.keys().then((keys) =>
        keys.forEach(async (key) => {
            if (!key.startsWith("staff.global")) await caches.delete(key);
        })
    );
};

export const clearCurrentSystemCache = async () => {
    if (!caches) return;
    const storageManager = StorageManager.GetInstance();
    const subjectId = storageManager.retrieve("staff.currentUserId", EStorageType.LocalStorage, true);
    const systemName = storageManager.retrieve("staff.lastUsedSystem", EStorageType.SessionStorage, true);
    return await caches.delete(`staff.${systemName}:${subjectId}`);
};

export const clearExpiredCachedResponses = () => {
    if (!caches) return;
    console.log("Clearing expired cached responses");
    const now = new Date().getTime();
    caches.keys().then((keys) =>
        keys.forEach(async (key) => {
            const cache = await caches.open(key);
            cache.keys().then((responseKeys) =>
                responseKeys.forEach(async (responseKey) => {
                    const response = await cache.match(responseKey);
                    if (response) {
                        const daysToCache = response.headers.get("staff.days-to-cache") ? +(response.headers.get("staff.days-to-cache") as string) : 60;
                        const cacheDate = Date.parse(response.headers.get("staff.cache-created-on") as string);
                        const daysBetweenDates = Math.round((now - cacheDate) / oneDayInMs);
                        if (daysBetweenDates > daysToCache) {
                            // Cached response no longer valid
                            await cache.delete(responseKey);
                        }
                    }
                })
            );
        })
    );
};

export const invalidateCacheVersion = (versionKey: string, globalCache: boolean = false) => {
    const storageManager = StorageManager.GetInstance();
    if (globalCache) {
        const globalServerKeysJson = storageManager.retrieve(globalCacheKeys, EStorageType.LocalStorage);
        const globalServerKeysObj = globalServerKeysJson ? JSON.parse(globalServerKeysJson) : {};
        globalServerKeysObj[versionKey] = -1;
        // Store global server cache keys
        storageManager.store(globalCacheKeys, JSON.stringify(globalServerKeysObj) || "", EStorageType.LocalStorage, true);
    } else {
        const systemName = storageManager.retrieve("staff.lastUsedSystem", EStorageType.SessionStorage);
        if (systemName) {
            const systemServerKeysJson = storageManager.retrieve(`staff.${systemName}:cacheKeys`, EStorageType.LocalStorage);
            const systemServerKeysObj = systemServerKeysJson ? JSON.parse(systemServerKeysJson) : {};
            systemServerKeysObj[versionKey] = -1;
            // Store system cache keys
            storageManager.store(`staff.${systemName}:cacheKeys`, JSON.stringify(systemServerKeysObj) || "", EStorageType.LocalStorage, true);
        }
    }
};
